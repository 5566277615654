import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				ТехноСервіс Плюс
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:title"} content={"ТехноСервіс Плюс"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});